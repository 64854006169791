import "./alpacalink.css";
import { gql, useMutation } from "@apollo/client";

const ADD_USER_TO_BOT_QUEUE = gql`
    mutation AddUserToBotQueue($email: String!) {
        addUserToBotQueue(email: $email) 
    }
`;

const AlpacaLink = ({ email }) => {
    const [addUserToBotQueue, { loading, error }] = useMutation(ADD_USER_TO_BOT_QUEUE);

    const handleLinkClick = async () => {
        try {
            const { data } = await addUserToBotQueue({ variables: { email } });
        } catch (err) {
        }
    };

    return (
        <div className="alpacalink-container">
            <div>
                You have not linked your Alpaca account! Currently, BiasCoin only works if you have an active 
                Alpaca Trade account that you link to your BiasCoin subscription. Press this button to create your bot!
            </div>
            <button onClick={handleLinkClick} disabled={loading}>
                {loading ? "Linking..." : "Link your Alpaca Account"}
            </button>
            {error && <p className="error">Error: {error.message}</p>}
        </div>
    );
}

export default AlpacaLink;