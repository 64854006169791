import React, { useEffect, useRef, useState } from "react";

const FrequencyHistogram = ({ frequencies }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 600, height: 300 });

  
  // Resize the canvas dynamically
  useEffect(() => {
    const resizeCanvas = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.clientWidth;
        setCanvasSize({
          width: newWidth,
          height: Math.min(300, newWidth / 2), // Maintain a 2:1 ratio
        });
      }
    };

    resizeCanvas(); // Call initially
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  // Draw the histogram
  useEffect(() => {
    if (!frequencies || frequencies.length === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set the new canvas size
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    // Canvas dimensions
    const { width, height } = canvasSize;
    const paddingLeft = 60; // Increased left padding for Y-axis label
    const paddingRight = 20; // Smaller right padding
    const paddingBottom = 50; // Increased to prevent text cutoff
    const paddingTop = 30; // Space for frequency labels
    const barWidth = (width - paddingLeft - paddingRight) / frequencies.length;

    // Convert frequency percentages to numbers
    const formattedData = frequencies.map((item) => ({
      label: parseFloat(item.label),
      frequency: parseFloat(item.frequency.replace("%", "")), // Remove '%' and convert
    }));

    // Find the maximum frequency to normalize the bars
    const maxFrequency = Math.max(...formattedData.map((d) => d.frequency));

    // Clear the canvas before drawing
    ctx.clearRect(0, 0, width, height);

    // Draw bars and top labels
    formattedData.forEach((data, index) => {
        const x = paddingLeft + index * barWidth;
        const barHeight = (data.frequency / maxFrequency) * (height - paddingBottom - paddingTop);
        const y = height - paddingBottom - barHeight;
        const barCenter = x + barWidth / 2; // Midpoint of the bar
    
        // Draw the bars
        ctx.fillStyle = "#4a90e2"; // Bar color
        ctx.fillRect(x, y, barWidth - 2, barHeight);
    
        // Draw frequency percentage on top of each bar
        ctx.fillStyle = "#000";
        ctx.font = `${Math.max(10, width / 80)}px Arial`;
        ctx.textAlign = "center";
        ctx.fillText(`${data.frequency.toFixed(2)}%`, barCenter, y - 5);
        
        // Draw labels (X-Axis)
        if (index % 2 === 0) { // Skip every other label for readability
            ctx.fillText(data.label.toFixed(3), barCenter, height - paddingBottom + 12);
        }
    
        // Draw tick marks on the X-Axis
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(barCenter, height - paddingBottom);
        ctx.lineTo(barCenter, height - paddingBottom + 5); // Small tick mark
        ctx.stroke();
    });
      

    // Draw Y-Axis Labels (Left Side)
    ctx.fillStyle = "#000";
    ctx.font = "12px Arial";
    ctx.textAlign = "right";

    // Add multiple Y-axis labels
    for (let i = 0; i <= 5; i++) {
      const freqValue = ((maxFrequency / 5) * i).toFixed(1);
      const yPosition = height - paddingBottom - (i * (height - paddingBottom - paddingTop) / 5);
      ctx.fillText(`${freqValue}%`, paddingLeft - 10, yPosition + 4);
    }

    // Draw Y-Axis Label "Frequency (%)"
    ctx.save();
    ctx.translate(15, height / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.textAlign = "center";
    ctx.fillText("Frequency (%)", 0, 0);
    ctx.restore();

    // Draw Axes
    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(paddingLeft, height - paddingBottom);
    ctx.lineTo(width - paddingRight, height - paddingBottom); // X-Axis
    ctx.moveTo(paddingLeft, height - paddingBottom);
    ctx.lineTo(paddingLeft, paddingTop); // Y-Axis
    ctx.stroke();
  }, [frequencies, canvasSize]);

  return (
    <div ref={containerRef} style={{ width: "100%", maxWidth: "800px", margin: "0 auto", textAlign: "center" }}>
      <h3>Log Return Frequency Distribution</h3>
      <canvas ref={canvasRef} style={{ border: "1px solid #ddd", width: "100%", height: "auto" }} />
    </div>
  );
};

export default FrequencyHistogram;
