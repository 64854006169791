// Helper function that null-checks the value and formats it with two decimals:
const formatDecimals = (value, d) => {
    return value != null ? value.toFixed(d) : "N/A";
  };
  
  const CalculatedStatistics = ({ data }) => {
    // Safeguard so `data` is never `undefined`:
    const safeData = data ?? {};
  
    let rl;
    if (safeData.rlAction === 0) rl = "Hold";
    if (safeData.rlAction === -1) rl = "Sell";
    if (safeData.rlAction === 1) rl = "Buy";
  
    return (
      <div className="calculatestatistics-container">
        <h3>Calculated Statistics</h3>
        <div>Hamilton-Jacobi-Bellman Daily Price Targets</div>
        <div>Buy: {formatDecimals(safeData.hjbBuy, 2)}</div>
        <div>Sell: {formatDecimals(safeData.hjbSell, 2)}</div>
        <div>Daily Drift: {formatDecimals(safeData.drift, 5)}</div>
        {/* Check before multiplying to avoid errors */}
        <div>
          Annualized Drift:{" "}
          {safeData.drift != null ? (safeData.drift * 252).toFixed(5) : "N/A"}
        </div>
        <div>Daily Volatility: {formatDecimals(safeData.volatility, 5)}</div>
        <div>
          Annualized Volatility:{" "}
          {safeData.volatility != null
            ? (safeData.volatility * Math.sqrt(252)).toFixed(5)
            : "N/A"}
        </div>
        <div>52 Week High: {formatDecimals(safeData.high52, 2)}</div>
        <div>52 Week Low: {formatDecimals(safeData.low52, 2)}</div>
        <div>Reinforcement Learning Prediction: {rl}</div>
        <div>Arima Next Day: {formatDecimals(safeData.arimaNextDay, 2)}</div>
        <div>Arima Friday: {formatDecimals(safeData.arimaFriday, 2)}</div>
        <div>Monte Carlo Friday Prices</div>
        <div>Lower: {formatDecimals(safeData.monteCarloLower, 2)}</div>
        <div>Expected: {formatDecimals(safeData.monteCarloPrice, 2)}</div>
        <div>Upper: {formatDecimals(safeData.monteCarloUpper, 2)}</div>
      </div>
    );
  };
  
  export default CalculatedStatistics;
  