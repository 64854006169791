import React, { useEffect } from 'react';
import "./botstatus.css";
import { useQuery } from '@apollo/client';
import { GET_STATUS } from '../../../utils/queries';

const BotStatus = ({ email, updateCash, updateSpreadNumber }) => {
    // Use Apollo Client's `useQuery` hook
    const { loading, error, data, refetch } = useQuery(GET_STATUS, {
        variables: { botOwner: email },
    });

    // Extract cash value from the query data or fallback to 0
    const cash = data?.getBotStatus?.cash || 0;
    const numberOfSpreads = data?.getBotStatus?.numberOfSpreads || 0;

    // Update cash in the parent component
    useEffect(() => {
        updateCash(cash);
    }, [cash, updateCash]); // Always call updateCash when cash changes

    useEffect(() => {
        updateSpreadNumber(numberOfSpreads);
    }, [numberOfSpreads, updateSpreadNumber]);

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 5000);
        return () => clearInterval(interval);
    }, [refetch]);
    
    // Handle loading and error states
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // Extract bot status safely, default to a "down" status if null
    const bot = data?.getBotStatus || {};
    const isUp = bot.status !== null && bot.status !== undefined; // Red light if null or undefined
    
    // Determine market status class
    const marketClass = bot.marketStatus === "day" ? "up" : "down";

    return (
        <div className="status-container">
            <div>
                <h2>Market Status</h2>
                <div className={`status-indicator ${marketClass}`} />
            </div>
            <div>
                <h2>Bot Status</h2>
                <div className={`status-indicator ${isUp ? 'up' : 'down'}`} />
            </div>
        </div>
    );
};

export default BotStatus;
