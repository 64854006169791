import { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { GET_VOLATILITY, QUERY_CURRENT_PRICE } from '../../../utils/queries';
import "./volatilitycontainer.css";
import ProbabilityChart from "./ProbabilityChart";

const VolatilityContainer = () => {
    const volatilityQuery = useQuery(GET_VOLATILITY, {});
    const spyQuery = useQuery(QUERY_CURRENT_PRICE, { variables: { symbol: "SPY" } });

    useEffect(() => {
        const interval = setInterval(() => {
            volatilityQuery.refetch();
            spyQuery.refetch();
        }, 30 * 1000);

        return () => clearInterval(interval);
    }, [volatilityQuery.refetch, spyQuery.refetch]);

    if (volatilityQuery.loading || spyQuery.loading) {
        return (<></>);
    }

    const vix = parseFloat(volatilityQuery.data.getVolatility[0].vix);
    const vix7 = Math.round(vix * Math.sqrt(7 / 30) * 100) / 100;
    const realized = volatilityQuery.data.getVolatility[0].realized;
    const skew = parseFloat(volatilityQuery.data.getVolatility[0].skew);
    const fear = volatilityQuery.data.getVolatility[0].fear;
    const caution = volatilityQuery.data.getVolatility[0].caution;
    const spy = parseFloat(spyQuery.data.getCurrentPrice.currentPrice);

    const downSideAdjustment = 1 + (skew - 100) / 200;
    const downSideMove = spy * (vix / 100) * Math.sqrt(7 / 252) * downSideAdjustment;
    const lowerBoundSpy = Math.round((spy - downSideMove) * 100) / 100;
    const upSideAdjustment = 1 - (skew - 100) / 400;
    const upSideMove = spy * (vix / 100) * Math.sqrt(7 / 252) * upSideAdjustment;
    const upperBoundSpy = Math.round((spy + upSideMove) * 100) / 100;

    console.log(lowerBoundSpy, spy, upperBoundSpy);

    return (
        
            <div className="volatility-container">
                <div>VIX: {vix}</div>
                <div>Realized: {realized}</div>
                <div>SKEW: {skew}</div>
                <div>Fear: {fear}%</div>
                <div>Caution: {caution}%</div>
            </div>
            
    );
}

export default VolatilityContainer;