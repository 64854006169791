import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";

// Mutation to update the position.
const UPDATE_POSITION = gql`
  mutation UpdatePosition($email: String!, $symbol: String!, $checked: Boolean!) {
    updatePosition(email: $email, symbol: $symbol, checked: $checked) {
      user
      symbol
      hold
    }
  }
`;

const PositionCheckbox = ({ email, symbol, hold }) => {
    const [isChecked, setIsChecked] = useState(hold);

    useEffect(() => {
        setIsChecked(hold);
    }, [hold]);

    const [updatePosition, { loading: mutationLoading }] = useMutation(UPDATE_POSITION);
    const handleCheckboxChange = async (event) => {
        const checked = event.target.checked;
        setIsChecked(checked);
        try {
            await updatePosition({
                variables: { email, symbol, checked }
            });
        } catch (e) {
            setIsChecked(!checked);
        }
    };



    return (
    <>
        <input 
        type="checkbox"
        checked={isChecked} 
        onChange={handleCheckboxChange}
        disabled={mutationLoading} 
         />
    </>);
}

export default PositionCheckbox;