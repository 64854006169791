

const CompanyContact = ({ data }) => {
    return (
        <div className="companycontact-container">
            <h3>Contact Info</h3>
            <a href={data?.companyWebsite ?? '/'} target="_blank"><div>{data?.companyWebsite}</div></a>
            <div>Phone Number: {data?.phoneNumber}</div>
            <h4>Address</h4>
            <div>{data?.address.address1}</div>
            <div>{data?.address.address2}</div>
            <div>{data?.address.city} {data?.address.state} {data?.address.postal_code}</div>
        </div>
    );
}

export default CompanyContact;