

const CompanyDescription = ({ data }) => {
    return (
        <div className="companydescription-container">
            <h3>Company Description</h3>
            <div>{data?.description}</div>
            <div>SIC: {data?.sicCode}</div>
            <div>SIC Description: {data?.sicDescription}</div>
            <div>Total Employees: {data?.employees}</div>
        </div>
    );
}

export default CompanyDescription;