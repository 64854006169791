

const RecentPrice = ({ data }) => {
    return (
        <div className="recentprice-container">
            <h3>Recent OHLCV</h3>
            <div>Volume: {data?.recentVolume}</div>
        </div>
    );
}

export default RecentPrice;