import "./companyfinancials.css";

const CompanyFinancials = ({ data }) => {
    
    return (
        <div className="companyfinancials-container">
            <h3>Financials</h3>
            <div>Operating Cash Flow: {data?.operatingIncome}</div>
            <div>Market Cap: {data?.marketCap}</div>
            <div>Shares Outstanding: {data?.sharesOutstanding}</div>
        </div>
    );
}

export default CompanyFinancials;