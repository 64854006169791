import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_STOCK_INFOS } from '../../utils/queries';
import { useNavigate } from "react-router-dom";
import "./firstpassagetimes.css";

const FirstPassageTimes = () => {
    const { loading, error, data } = useQuery(GET_STOCK_INFOS);
    const [filterActive, setFilterActive] = useState(true);
    const navigate = useNavigate();

    const handleRowClick = (symbol) => {
        navigate(`/${symbol}`);
    };

    if (loading) return <p></p>;
    if (error) return <p>Error.</p>;

    const allStockInfos = data?.getStockInfos || [];
    const displayedStockInfos = filterActive ? allStockInfos.filter(stock => stock.operatingIncome > 0 && stock.recentVolume > 1e6).sort((a, b) => a.averageFirstPassageTime - b.averageFirstPassageTime) : [...allStockInfos].filter(stock => stock.averageFirstPassageTime !== null && stock.marketType === "stocks" && stock.assetType === "CS").sort((a, b) => a.averageFirstPassageTime - b.averageFirstPassageTime);

    const toggleFilter = () => {
        setFilterActive(!filterActive);
    };

    return (
        <div className="firstpassagetimes-container">
            <button onClick={toggleFilter}>
                {filterActive ? 'Show All Stocks' : 'Show Only Positive Operating Income'}
            </button>
            {displayedStockInfos.length === 0 ? (
                <p>No data available</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Stock</th>
                            <th>Average First-Passage Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedStockInfos.slice(0, 100).map((stock) => (
                            <tr key={stock.symbol} onClick={() => handleRowClick(stock.symbol)}>
                                <td>{stock.symbol}</td>
                                <td>{stock.averageFirstPassageTime?.toFixed(2) ?? ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default FirstPassageTimes;