import { gql } from '@apollo/client';

export const GET_STOCK_INFOS = gql`
    query GetStockInfos {
        getStockInfos {
            symbol
            averageFirstPassageTime
            address {
                address1
                address2
                city
                state
                postal_code
            }
            assetType
            cik
            companyWebsite
            description
            employees
            marketCap
            marketType
            operatingIncome
            phoneNumber
            recentVolume
            sharesOutstanding
            sicCode
            sicDescription
            high52
            low52
            hjbBuy
            hjbSell
            drift
            volatility
            rlAction
            arimaNextDay
            arimaFriday
            monteCarloLower
            monteCarloUpper
            monteCarloPrice
        }
    }
`;

export const GET_STOCK_INFO = gql`
    query GetStockInfo($symbol: String!) {
        getStockInfo(symbol: $symbol) {
            symbol
            averageFirstPassageTime
            address {
                address1
                address2
                city
                state
                postal_code
            }
            assetType
            cik
            companyWebsite
            description
            employees
            marketCap
            marketType
            operatingIncome
            phoneNumber
            recentVolume
            sharesOutstanding
            sicCode
            sicDescription
            high52
            low52
            hjbBuy
            hjbSell
            drift
            volatility
            rlAction
            arimaNextDay
            arimaFriday
            monteCarloLower
            monteCarloUpper
            monteCarloPrice
            frequencies {
                label
                frequency
            }
        }
    }
`;


export const GET_WATCHLIST = gql`
    query GetWatchList($email: String!) {
        getWatchList(email: $email) {
            email
            symbols
        }
    }
`;
export const GET_STATUS = gql`
    query GetBotStatus($botOwner: String!) {
        getBotStatus(botOwner: $botOwner) {
            botNumber
            botOwner
            marketStatus
            status
            cash
            numberOfSpreads
        }
    }
`;

export const GET_POSITIONS = gql `
    query GetPositions($user: String!) {
        getPositions(user: $user) {
            symbol
            averageEntryPrice
            currentPrice
            quantity
            unrealizedProfitLossPercentage
            hold
        }
    }
`;

export const GET_TRADE_EVENTS = gql`
    query GetTradeEvents {
        getTradeEvents {
            symbol
            strike
            size
            price
            optionType
            expiration
            dollarAmount
            timestamp
            priceSpike
        }
    }
`;

export const GET_OPTION_AGGREGATES = gql`
    query GetOptionAggregates($symbol: String!) {
        getOptionAggregates(symbol: $symbol) {
            aggregates {
                expiration
                optionType
                totalOpenInterest
                totalYesterdayOpenInterest
            }
            symbol
        }
    }
`;

export const GET_CASHMAP = gql`
    query GetCashMap {
        getCashMap {
            cash
            symbol
        }
    }
`;

export const GET_TOP_COMPANIES = gql`
    query GetTopCompanies {
        getTopCompanies {
            currentRatio
            debtToEquityRatio
            epsGrowth
            freeCashFlow
            grossMargin
            lastUpdated
            netIncomeGrowth
            operatingMargin
            peRatio
            revenueGrowth
            roe
            symbol
        }
    }
`;
export const GET_LOW_COST_OPTIONS = gql`
    query GetLowCostOptions($symbol: String) {
        getLowCostOptions(symbol: $symbol) {
            cost
            delta
            expiration
            lastPrice
            optionType
            quantity
            strike
            symbol
        }
    }
`;

export const GET_STOCK_HISTORY = gql`
    query GetHistoricalPrices($symbol: String!, $timeframe: String) {
        getHistoricalPrices(symbol: $symbol, timeframe: $timeframe) {
            c
            h
            l
            n
            o
            timestamp
            volume
            vw
        }
    }
`;

export const GET_OPTIONCHAIN = gql`
    query GetOptionChain($symbol: String!) {
        getOptionChain(symbol: $symbol) {
            gamma
            expiration
            breakEvenPrice
            delta
            vega
            theta
            theoreticalPrice
            symbol
            strike
            stockPrice
            riskFreeRate
            realizedVolatility
            optionType
            optionTicker
            openInterest
            mostRecentDay {
                change
                change_percent
                close
                vwap
                volume
                previous_close
                open
                low
                last_updated
                high
            }
            extrinsicValue
            fetchedDelta
            fetchedGamma
            fetchedImpliedVolatility
            fetchedTheta
            fetchedVega
            impliedVolatility
            intrinsicValue
            itmProbability
            lastFiveDays {
                c
                h
                l
                n
                o
                t
                v
                vw
            }
            openInterestAverage
            openInterestChange
            volumeAverage
            lastUpdated
            midPrice
            askPrice
            askQuantity
            bidPrice
            bidQuantity
        }
    }
`;
//lastFiveDays
// mostRecentDay

export const GET_VOLATILITY = gql`
    query GetVolatility {
        getVolatility {
            realized
            skew
            vix
            fear
            caution
        }
    }
`;

export const QUERY_CURRENT_PRICE = gql`
    query GetCurrentPrice($symbol: String!) {
        getCurrentPrice(symbol: $symbol) {
            currentPrice
            symbol
        }
    }
`;

export const GET_PREDICTION = gql`
    query GetPrediction($symbol: String!) {
        getPrediction(symbol: $symbol) {
        symbol
        lastUpdated
        linearRegressionPrice
        linearRegressionRange
        arimaPrice
        arimaRange
        lstmPrice
        lstmRange
    }
}
`;

export const CHECK_SUBSCRIPTION = gql`
    query CheckSubscription($email: String!) {
        checkSubscription(email: $email) {
            email
            isSubscribed
        }
    }
`;

export const QUERY_ACCOUNT = gql`
    query User($email: String!) {
        user(email: $email) {
            email
            isSubscribed
            fullName {
                firstName
                lastName
            }
            customerId
            subscriptionId
        }
    }
`;



export const QUERY_FEAR = gql`
  query Fear {
    fear {
      fearValue
    }
  }
`;

export const QUERY_USER_HAND = gql`
    query getUser($email: String!) {
        user(email: $email) {
            isSubscribed
            hand
        }
    }
`;

export const QUERY_STRATEGIES = gql`
    query {
        strategies {
            identity
            title
            symbol
            signal
            buyDate
            buyPrice
            sellPrice
            sellDate
            backtest
            maxReturn
            shortPrice
            shortDate
            coverPrice
            coverDate
            maxDrawdown
            probabilityOfSuccess
            numberOfTrades
            indicatorNames
            indicatorValues
            tradeTime
            annualReturn
        }
    }
`;

export const QUERY_STRATEGY = gql`
    query getStrategy($title: String, $symbol: String) {
        strategy(title: $title, symbol: $symbol) {
            identity
            title
            symbol
            signal
            buyDate
            buyPrice
            sellPrice
            sellDate
            backtest
            maxReturn
            shortPrice
            shortDate
            coverPrice
            coverDate
            maxDrawdown
            probabilityOfSuccess
            numberOfTrades
            indicatorNames
            indicatorValues
            tradeTime
            tradeHistory
            annualReturn
        }
    }
`;

export const QUERY_FEDRATES = gql`
    query {
        fedRate {
            currentRate
            previousRate
        }
    }
`;

export const QUERY_INFLATIONRATE = gql`
    query {
        infRate {
            currentRate
            previousRate
        }
    }
`;

export const QUERY_POLITICALMEASURE = gql`
    query {
        politicalCount {
            houseRatio
            senateRatio
            executiveRatio
            judicialRatio
        }
    }
`;


export const UPDATE_NEWS = gql`
    query {
       updateNews {
        id
        publisher {
            name
            homepageUrl
            logoUrl
            faviconUrl
        }
        title
        author
        publishedUtc
        articleUrl
        tickers
        ampUrl
        imageUrl
        description
        keywords
        content
       } 
    }
`;

