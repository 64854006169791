import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
    ApolloProvider,
    ApolloClient,
    createHttpLink,
    InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { symbols } from './utils/symbols';

// Components
import NavBar from './components/NavBar';
import Header from './components/Header';

// Pages
import Home from './pages/Home/index';
import Terms from './pages/Terms/index';
import Account from './pages/Account';
import Logout from './pages/Logout';
import Success from "./pages/Success";

// Utils
import Auth from './utils/auth';
import CreateStockRoutes from './utils/CreateStockRoutes';
import FirstPassageTimes from './pages/FirstPassageTimes';

const httpLink = createHttpLink({
    uri: process.env.NODE_ENV === "production" ? "/graphql" : "http://localhost:4000/graphql"
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers
        }
    }
});
const client = new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === "production" ? false : true,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

function App() {
    const loggedin = Auth.loggedIn();
    const email = loggedin ? Auth.getProfile().data.email : "";
    const isSubscribed = email !== "" ? Auth.getProfile().data.isSubscribed : false;
    const stockRoutes = CreateStockRoutes(symbols, loggedin, isSubscribed);

    return (
        <ApolloProvider client={client}>
            <div className="all">
                <Header />

                <Router>
                    <Routes>
                        <Route exact path="/" element={<Home loggedin={loggedin} email={email} isSubscribed={isSubscribed} />} />
                        <Route exact path="/terms-of-use" element={<Terms loggedin={loggedin} />} />
                        <Route exact path="/account" element={<Account loggedin={loggedin} email={email} isSubscribed={isSubscribed} />} />
                        <Route exact path="/logout" element={<Logout />} />
                        <Route exact path="/success" element={<Success />} />
                        <Route exact path="/fpt" element={<FirstPassageTimes />} />
                        {stockRoutes}
                    </Routes>
                    
                </Router>

                <NavBar loggedin={loggedin} />
            </div>
        </ApolloProvider>
    );
}

export default App;
