import './stock.css';
import Footer from '../../components/Footer';
import Patron from './Patron/Patron';
import StockTitle from './StockTitle';
import StockChart from "./StockChart/StockChart";
import TimeFrames from "./TimeFrames/TimeFrames";
import { useQuery } from "@apollo/client";
import { GET_STOCK_HISTORY, GET_STOCK_INFO } from '../../utils/queries';
import { useState } from "react";
import CompanyFinancials from './CompanyFinancials/CompanyFinancials';
import CompanyDescription from './CompanyDescription';
import Prediction from "./Prediction/Prediction";
import LoadingComponent from "./LoadingComponent";
import CalculatedStatistics from './CalculatedStatistics';
import CompanyContact from './CompanyContact';
import RecentPrice from './RecentPrice';
import FrequencyHistogram from './FrequencyHistogram';

const Stock = ({ symbol, loggedin, isSubscribed }) => {
    const [chartMode, setChartMode] = useState("5Y");
    const stockDataQuery = useQuery(GET_STOCK_HISTORY, { 
        variables: { symbol: symbol, timeframe: "Daily" } 
    });
    const stockInfoQuery = useQuery(GET_STOCK_INFO, { variables: { symbol: symbol }});

    if (stockDataQuery.loading || stockInfoQuery.loading) return <><LoadingComponent symbol={symbol} /></>;
    if (stockDataQuery.error || stockInfoQuery.error) return <></>;

    const closingPrices = stockDataQuery.data.getHistoricalPrices.map(item => item.c);
    const timestamps = stockDataQuery.data.getHistoricalPrices.map(item => item.timestamp);
    const stockInfoData = stockInfoQuery.data.getStockInfo;
    
    // Helper to filter data based on chart mode
    const filterDataByMode = (mode) => {
        switch (mode) {
            case "5Y":
                return { prices: closingPrices, labels: timestamps };  // All data
            case "1Y":
                return { 
                    prices: closingPrices.slice(-252),  // Last 252 trading days (~1 year)
                    labels: timestamps.slice(-252) 
                };
            case "1M":
                return { 
                    prices: closingPrices.slice(-20),  // Last 20 trading days (~1 month)
                    labels: timestamps.slice(-20) 
                };
            case "1W":
                return { 
                    prices: closingPrices.slice(-5),  // Last 5 trading days (~1 week)
                    labels: timestamps.slice(-5) 
                };
            default:
                return { prices: closingPrices, labels: timestamps };
        }
    };

    const { prices, labels } = filterDataByMode(chartMode);

    return (
        <div className="stock-container">
            <StockTitle symbol={symbol} />
            <StockChart data={prices} labels={labels} mode={chartMode} />
            <TimeFrames chartMode={chartMode} setChartMode={setChartMode} />
            <hr />
            <FrequencyHistogram frequencies={stockInfoData?.frequencies} />
            <hr />
            <Prediction />
            <hr />
            <CompanyFinancials data={stockInfoData} />
            <CalculatedStatistics data={stockInfoData} />
            <RecentPrice data={stockInfoData} />
            <CompanyDescription data={stockInfoData} />
            <CompanyContact data={stockInfoData} />
            <Footer />
        </div>
    );
}

export default Stock;